"use strict";

var app = {
  mobileMenu: function mobileMenu() {
    var mobileBtn = $("#mobile-burger-btn");
    var pageBody = $("#page-wrap-inner");

    if (mobileBtn.length > 0) {
      // Load Menu
      mobileBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") == true) {
          pageBody.removeClass("push");
          mobileBtn.removeClass("menu-open");
        } else {
          pageBody.addClass("push");
          mobileBtn.addClass("menu-open");
        }
      });
    }
  },
  validateEmail: function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  topicsFilter: function topicsFilter() {

    $('.fltrselect').on('change', function () {
      //alert( this.value );
      if (this.value == 'all') {
        $('#parent > div').fadeIn(450);
      } else {
        var $el = $('.' + this.value).fadeIn(450);
        $('#parent > div').not($el).hide();
      }
      $btns.removeClass('active');
      $(this).addClass('active');
    });
  },
  init: function init() {
    app.mobileMenu();

    if ($('#topicsFilter').length > 0) {
      app.topicsFilter();
    }

    $("#topics").slick({
      dots: false,
      infinite: true,
      autoplay: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: "#nxt-btn",
      prevArrow: "#prev-btn",
      cssEase: "linear",
      responsive: [{
        breakpoint: 915,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }, {
        breakpoint: 615,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      }]
    });
  }
};

$(document).ready(function () {
  app.init();
});